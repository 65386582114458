<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
                page-title-box
                d-sm-flex
                align-items-center
                justify-content-between
              ">
                        <h4 class="mb-sm-0">Investor</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item active">Form Investor</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Form Investor</h4>
                            <div class="flex-shrink-0">
                                <router-link :to="{ name: 'marketing' }" type="button"
                                    class="btn btn-warning waves-effect waves-light"><i
                                        class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                                    Kembali
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body">
                            <form class="" @submit.prevent="submit">






                                <h5 class="text-center text-primary">Biodata</h5>

                                <div class="mb-3">
                                    <label class="form-label">Email</label>
                                    <input v-model="register.email" type="text" class="form-control"
                                        placeholder="Masukkan email" required />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Name</label>
                                    <input v-model="register.name" type="text" class="form-control"
                                        placeholder="Masukkan Nama" required />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Alamat</label>
                                    <textarea v-model="register.address" type="text" class="form-control"
                                        placeholder="Masukkan Alamat" required></textarea>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Nomor ID (KTP/Passport)</label>
                                    <input v-model="register.nik" type="number" class="form-control"
                                        placeholder="Masukkan Nomor ID (KTP/Passwort)" required />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Foto KTP/Passport</label>
                                    <input type="file" class="form-control" accept="image/png, image/jpeg, image/jpg"
                                        @change="ktpChange($event)" required />
                                    <small class="text-muted">Max. 10MB (Jika melebihi tidak akan terupload dan proses
                                        verifikasi akan
                                        lebih lama)</small>
                                </div>

                                <div class="mb-4">
                                    <label class="form-label">Nomor Telepon (WA)</label>
                                    <input v-model="register.phone_number" type="number" class="form-control"
                                        placeholder="Masukkan Nomot Telepon (WA)" required />
                                </div>




                                <h5 class="text-center text-primary mt-2">Akun Bank</h5>

                                <div class="mb-3">
                                    <label class="form-label">Nama Bank</label>
                                    <input v-model="register.bank_name" type="text" class="form-control"
                                        placeholder="Masukkan Nama Bank Contoh:BRI,BNI" required />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Nama Akun Bank</label>
                                    <input v-model="register.bank_account_name" type="text" class="form-control"
                                        placeholder="Masukkan Nama Akun Bank Contoh:Monaroh" required />
                                </div>

                                <div class="mb-4">
                                    <label class="form-label">Nomor Akun Bank</label>
                                    <input v-model="register.bank_account_number" type="number" class="form-control"
                                        placeholder="Masukkan Nomor Akun Bank Contoh:111222333" required />
                                </div>




                                <h5 class="text-center text-primary">Biodata Ahli Waris</h5>

                                <div class="mb-3">
                                    <label class="form-label">Nama Ahli Waris</label>
                                    <input v-model="register.heir_name" type="text" class="form-control"
                                        placeholder="Masukkan Nama Ahli Waris" required />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Nomor ID Ahli Waris (KTP/Passport)</label>
                                    <input v-model="register.heir_nik" type="number" class="form-control"
                                        placeholder="Masukkan Nomor ID Ahli Waris (KTP/Passport)" required />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Nomor Telopon Ahli Waris (WA)</label>
                                    <input v-model="register.heir_phone_number" type="number" class="form-control"
                                        placeholder="Masukkan Nomor Telepon Ahli Waris" required />
                                </div>






                                <div class="alert alert-info alert-borderless" role="alert">
                                    Password default untuk user baru adalah
                                    <strong>123456</strong>
                                </div>
                                <div class="float-end">
                                    <router-link :to="{ name: 'marketing' }" type="button"
                                        class="btn btn-warning waves-effect waves-light me-2"><i
                                            class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                                        Kembali
                                    </router-link>
                                    <button :disabled="load" type="submit"
                                        class="btn btn-primary waves-effect waves-light">
                                        <i class="bx font-size-16 align-middle me-2"
                                            :class="{ 'bx-loader bx-spin': load, 'bx-save': !load }"></i>
                                        Simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
  import httpService from "../../services/http-service";
  import { endpoint } from "../../host";
  import Swal from "sweetalert2";
  import errorService from "../../services/error-service";
  export default {
    components: {},
    created() {
      var params = this.$route.params;
      if (params.id !== undefined) {
        this.assignParams(params);
      }
    },
    data() {
      return {
        title: null,
        load: false,
        register: {
        email: null,
        password: '123456',
        password_confirmation: '123456',
        name: null,
        nik: null,
        phone_number: null,
        address: null,
        bank_name: null,
        bank_account_number: null,
        bank_account_name: null,
        heir_nik: null,
        heir_name: null,
        heir_phone_number: null,
        share_period_id: null,
        number_of_slot: 1,
        ktp: null,
        evidence_of_transfer: null,
      },
      type: {
        password: "password",
        password_confirmation: "password",
      },
      };
    },
    methods: {
    async submit() {
      try {
        this.load = true;
        let formData = new FormData();
        for (const key in this.register) {
          formData.append(key, this.register[key] ?? "");
        }
        let r = await httpService.post(endpoint.investor, formData);
        this.load = false;
        if (r.status == 200) {
            Swal.fire({
            title: "Berhasil",
            text: "data berhasil disimpan",
            icon: "success",
          });
          this.$router.push({
            name: "investor",
          });
        }
      }
      catch (error) {
        this.load = false;
        errorService.displayError(error);
      }
    },

    ktpChange(event) {
      this.register.ktp = event.target.files[0];
    },
    
    onPassword(a) {
      if (a == "password") {
        this.type.password == "password"
          ? (this.type.password = "text")
          : (this.type.password = "password");
      }
      else if (a == "password_confirmation") {
        this.type.password_confirmation == "password"
          ? (this.type.password_confirmation = "text")
          : (this.type.password_confirmation = "password");
      }
    },
  },
  };
  </script>
  
  <style>
  </style>